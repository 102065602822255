<div #header *ngIf="(mediaPartner || supporter || exhibitor)" class="header"></div>

<div *ngIf="(mediaPartner || supporter || exhibitor)" class="exhibitor-single-page">
    <div class="container">
        <div class="exhibitor-details">
            <ng-container *ngIf="exhibitor">
                <div class="logo">
                    <img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}" />
                </div>
                <h2 *ngIf="exhibitor.boothType.name">{{exhibitor.boothType.name}} Exhibitor</h2>
                <div class="exhibitor-info">
                    <div class="left">
                        <p>{{exhibitor.vertical}}<span *ngIf="exhibitor.vertical && exhibitor.location"> |
                            </span>{{exhibitor.location}}</p>
                    </div>
                    <div class="right">
                        <p><span *ngIf="exhibitor.boothType.name || exhibitor.boothRef">Booth:</span>
                            {{exhibitor.boothType.name}} <span
                                *ngIf="exhibitor.boothType.name && exhibitor.boothRef">/</span> {{exhibitor.boothRef}}
                        </p>
                    </div>
                </div>
                <div class="tags" *ngIf="exhibitor.tags !== null">
                    <p *ngFor="let tag of exhibitor.tags;">{{tag}}</p>
                </div>
                <p class="desc">{{exhibitor.description}}</p>
                <div *ngIf="exhibitor.website">
                    <a class="main-btn" href="{{exhibitor.website}}" target="_blank" rel="nofollow">Visit Site</a>
                </div>

                <a class="back" [routerLink]="['/exhibitors']" [fragment]="replaceSpaces(exhibitor.companyName)">back to
                    site</a>
            </ng-container>

            <ng-container *ngIf="supporter">
                <div class="logo">
                    <img src="{{supporter.apiLogoUrl}}" alt="Logo of {{supporter.companyName}}" />
                </div>
                <h2>i-Con Supporter</h2>
                <div class="exhibitor-info">
                    <p>{{supporter.vertical}}<span *ngIf="supporter.vertical && supporter.location"> |
                        </span>{{supporter.location}}</p>
                </div>
                <div class="tags" *ngIf="supporter.tags !== null">
                    <p *ngFor="let tag of supporter.tags;">{{tag}}</p>
                </div>
                <p class="desc">{{supporter.description}}</p>
                <div *ngIf="supporter.website">
                    <a class="main-btn" href="{{supporter.website}}" target="_blank" rel="nofollow">Visit Site</a>
                </div>

                <a class="back" [routerLink]="['/exhibitors']" [fragment]="replaceSpaces(supporter.companyName)">back to
                    site</a>
            </ng-container>

            <ng-container *ngIf="mediaPartner">
                <div class="logo">
                    <img src="{{mediaPartner.apiLogoUrl}}" alt="Logo of {{mediaPartner.companyName}}" />
                </div>
                <h2>Media Partner</h2>
                <p class="desc">{{mediaPartner.description}}</p>
                <div *ngIf="mediaPartner.website">
                    <a class="main-btn" href="{{mediaPartner.website}}" target="_blank" rel="nofollow">Visit Site</a>
                </div>

                <a class="back" [routerLink]="['/exhibitors']" [fragment]="replaceSpaces(mediaPartner.companyName)">back
                    to site</a>
            </ng-container>

            <ng-container *ngIf="speakerCollaborator">
                <div class="logo">
                    <img src="{{speakerCollaborator.apiLogoUrl}}" alt="Logo of {{speakerCollaborator.companyName}}" />
                </div>
                <h2>Speaker Collaborator</h2>
                <p class="desc">{{speakerCollaborator.description}}</p>
                <div *ngIf="speakerCollaborator.website">
                    <a class="main-btn" href="{{speakerCollaborator.website}}" target="_blank" rel="nofollow">Visit Site</a>
                </div>

                <a class="back" [routerLink]="['/exhibitors']" [fragment]="replaceSpaces(speakerCollaborator.companyName)">back
                    to site</a>
            </ng-container>

        </div>
    </div>
</div>
<app-not-found *ngIf="!(speakerCollaborator || mediaPartner || supporter || exhibitor)"></app-not-found>