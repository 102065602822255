import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { IParticipantType } from '../../_models/participants.interface';
import { IParticipantTypeResponse } from '../../_models/responses/participant-type-response.interface';
import { EnvironmentService, StateService } from '@i-con/shared/common';

const TRST_PARTICIPANTS = makeStateKey('participants');

interface IParticipantState {
  data: IParticipantType;
}

const initialStateValue: any = {
  data: {
    exhibitors: [
      {
        apiLogoUrl: '',
        boothRef: '',
        companyName: '',
        location: '',
        vertical: '',
        description: '',
        website: '',
        tags: [],
        boothType: {
          inStock: false,
          id: 1,
          name: '',
          referenceName: '',
          description: '',
          size: '',
          price: 0,
          color: '',
          floorSize: 0,
          boothTicketType: [
            {
              boothTypeId: 0,
              ticketTypeId: 0,
              quantity: 0,
              ticketType: {
                description: '',
                price: 0,
                discount: 0,
                onTheDoor: 0,
                discountEndsOn: new Date(),
                includes: [],
                inStock: false,
                id: 0,
                discounts: [],
              },
            },
          ],
          modelUrl: '',
          includes: [],
          positionWeight: 0,
          comboImageUrl: '',
          discounts: [
            {
              id: 0,
              percentage: 0,
              name: '',
              description: '',
              startsOn: new Date(),
              endsOn: new Date(),
            },
          ],
        },
        boughtWithCombo: false,
      },
    ],
    supporters: [
      {
        apiLogoUrl: '',
        boothRef: '',
        companyName: '',
        location: '',
        vertical: '',
        description: '',
        website: '',
        tags: [],
      },
    ],
    mediaPartners: [
      {
        apiLogoUrl: '',
        companyName: '',
        description: '',
        website: '',
      },
    ],
  },
};

@Injectable({ providedIn: 'root' })
export class ExhibitorService extends StateService<IParticipantState> {
  data$: Observable<IParticipantType> = this.getState(state => state.data);

  constructor(private http: HttpClient, private _environment: EnvironmentService, private transferState: TransferState) {
    super(initialStateValue);
    //this.getParticipants();
  }

  getParticipants(): void {
    const state_data = this.transferState.get(TRST_PARTICIPANTS, null as any);
    if (state_data) {
      this.setState({ data: state_data });
    } else {
      this.http.get<IParticipantTypeResponse>(`${this._environment.getApiUrl}/participants`).subscribe((res: IParticipantTypeResponse) => {
        this.setState({ data: res.response.data });
        this.transferState.set(TRST_PARTICIPANTS, res.response.data as any);
      });
    }
  }
}
