import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ExhibitorsRoutingModule } from "./exhibitors-routing.module";
import { ExhibitorsComponent } from './exhibitors.component';
import { ExhibitorSingleComponent } from './pages/exhibitor-single.component';
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
      ExhibitorsComponent,
      ExhibitorSingleComponent
    ],
    imports: [
      MatIconModule,
      CommonModule,
      ExhibitorsRoutingModule,
      SharedModule
    ],
    exports: [
      ExhibitorsComponent
    ]
  })
  export class ExhibitorsModule { }
  