import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IParticipantType } from '../../../core/_models/participants.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { IExhibitorType } from '../../../core/_models/exhibitors.interface';
import { ExhibitorService } from '../../../core/_services/exhibitors/exhibitors.service';
import { ISupporterType } from '../../../core/_models/supporters.interface';
import { IMediaPartnerType } from '../../../core/_models/media-partners.interface';
import { ISpeakerCollaboratorType } from '../../../core/_models/speaker-collaborators.interface';


@Component({
  selector: 'i-con-exhibitor-single',
  templateUrl: './exhibitor-single.component.html',
  styleUrl: './exhibitor-single.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExhibitorSingleComponent implements OnInit {
  //@Input() participantsList: IParticipantType[];

  participantsData$: Observable<IParticipantType>;
  exhibitor_company_name: any;
  
  exhibitor: IExhibitorType = {
    apiLogoUrl: '',
    boothRef: '',
    companyName: '',
    location: '',
    vertical: '',
    description: '',
    website: '',
    tags: [],
    boothType: {
      inStock: false,
      id: 1,
      name: '',
      referenceName: '',
      description: '',
      size: '',
      price: 0,
      color: '',
      floorSize: 0,
      boothTicketType: [{
        boothTypeId: 0,
        ticketTypeId: 0,
        quantity: 0,
        ticketType: {
          description: '',
          price: 0,
          discount: 0,
          onTheDoor: 0,
          discountEndsOn: new Date(),
          includes: [],
          inStock: false,
          id: 0,
          color: '',
          discounts: []
        }
      }],
      modelUrl: '',
      includes: [],
      positionWeight: 0,
      comboImageUrl: '',
      quantity: 0,
      discounts: [{
        id: 0,
        percentage: 0,
        name: '',
        description: '',
        startsOn: new Date(),
        endsOn: new Date(),
      }]
    },
    boughtWithCombo: false
  };


  supporter: ISupporterType = {
    apiLogoUrl: '',
    boothRef: '',
    companyName: '',
    location: '',
    vertical: '',
    website: '',
    tags: []
  };

  mediaPartner: IMediaPartnerType = {
    apiLogoUrl: '',
    companyName: '',
    website: ''
  };

  speakerCollaborator: ISpeakerCollaboratorType = {
    apiLogoUrl: '',
    companyName: '',
    website: ''
  };

  constructor(
		private activatedRouter: ActivatedRoute,
		private router: Router,
    private exhibitorService: ExhibitorService,
    private cd: ChangeDetectorRef) {
	} 

  ngOnInit(): void {

    this.participantsData$ = this.exhibitorService.data$;
    this.exhibitorService.getParticipants();

    this.exhibitor_company_name = this.activatedRouter.snapshot.paramMap.get('companyName');
  
    this.exhibitor_company_name = this.replaceSpaces(this.exhibitor_company_name);

    this.participantsData$.subscribe((res)=> {
      this.supporter = res.supporters.filter(sup => {
        return this.replaceSpaces(sup.companyName) == this.exhibitor_company_name
      })[0];

      this.exhibitor = res.exhibitors.filter(exh => {
        return this.replaceSpaces(exh.companyName) == this.exhibitor_company_name
      })[0];

      this.mediaPartner = res.mediaPartners.filter(med => {
        return this.replaceSpaces(med.companyName) == this.exhibitor_company_name
      })[0];

      this.speakerCollaborator = res.speakerCollaborators.filter(med => {
        return this.replaceSpaces(med.companyName) == this.exhibitor_company_name
      })[0];

      this.cd.detectChanges();
    })

  

    // this.exhibitor = participantsList.filter(exh => {
		// 	return exh.companyName == this.exhibitor_company_name
		// })[0];


    // this.participantsList.subscribe((res)=> {
    //     this.exhibitor = res.exhibitors.filter(exh => {
    //       return exh.companyName == this.exhibitor_company_name
    //     })[0];
    //   })

    
    

  }

  goToHome(): void {
		this.router.navigateByUrl('/exhibitors');
	}

  replaceSpaces(str: string): string {
    return str.replace(/ /g, '-').toLowerCase();
  }
}
