
<div #header class="header">
	<div class="container">
		<h1 class="pageheader"><span>The Complete List Of The</span><br/>ISLAND CONFERENCE EXHIBITORS</h1>
	</div>
</div>
<div class="exhibitors_page" *ngIf="participantsData$ | async as participants">
	<div class="container">
		<p>Meet i-Con's exhibitors, sponsors and supporters! Discover some of the biggest names in the industry and learn more about how they can help your brand reach new heights! Our list features marketing experts, affiliation executives, dating brands and well-known personalities from multiple industries.
			<br/><br/>Don't miss your chance to meet and network with every single one of them!
		</p>
		<div class="headline-sponsor category">
			<div class="left-dots"><img src="https://cdn-images.island-conference.com/attend/horizontal_dots.svg"/></div>
			<h3>Headline Sponsor</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boothType.id === 6">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
			<div class="right-dots"><img src="https://cdn-images.island-conference.com/attend/horizontal_dots.svg"/></div>
		</div>
		<div class="venus-exhibitors category">
			<h3>Venus Exhibitors</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo {{replaceSpaces(exhibitor.companyName.toLowerCase())}}" *ngIf="exhibitor.boothType.id === 5">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
			
		</div>
		<div class="mars-exhibitors category">
			<h3>Mars Exhibitors</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boothType.id === 4">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="galaxy-exhibitors category">
			<h3>Galaxy Exhibitors</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boothType.id === 3">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="combo-exhibitors category">
			<h3>Combo Exhibitors</h3>
			<p>Supporters with a Booth and an Expo Sponsorship Combo</p>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boughtWithCombo === true">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="mercury-exhibitors category">
			<h3>Mercury Exhibitors</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boothType.id === 2">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="moon-exhibitors category">
			<h3>Moon Exhibitors</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let exhibitor of participants.exhibitors; let i = index">
					<div id="{{replaceSpaces(exhibitor.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="exhibitor.boothType.id === 1">
						<button [ngClass]="{ filled: exhibitor.description !== null }" mat-button (click)="goToExhibitor(exhibitor)">
							<img src="{{exhibitor.apiLogoUrl}}" alt="Logo of {{exhibitor.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="speaker-collaborators category" *ngIf="participants.speakerCollaborators.length > 0">
			<h3>Speaker Collaborations</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let speakerCollaborator of participants.speakerCollaborators; let i = index">
					<div id="{{replaceSpaces(speakerCollaborator.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="speakerCollaborator">
						<button [ngClass]="{ filled: speakerCollaborator.description !== null }" mat-button (click)="goToSpeakerCollaborator(speakerCollaborator)">
							<img src="{{speakerCollaborator.apiLogoUrl}}" alt="Logo of {{speakerCollaborator.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="supporters-exhibitors category" *ngIf="participants.supporters.length > 0">
			<h3>i-Con Supporters</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let supporter of participants.supporters; let i = index">
					<div id="{{replaceSpaces(supporter.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="supporter">
						<button [ngClass]="{ filled: supporter.description !== null }" mat-button (click)="goToSupporter(supporter)">
							<img src="{{supporter.apiLogoUrl}}" alt="Logo of {{supporter.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="media-exhibitors category" *ngIf="participants.mediaPartners.length > 0">
			<h3>Media Partners</h3>
			<div class="exhibitors">
				<ng-container *ngFor="let mediaPartner of participants.mediaPartners; let i = index">
					<div id="{{replaceSpaces(mediaPartner.companyName.toLowerCase())}}" class="exhibitor-logo" *ngIf="mediaPartner">
						<button [ngClass]="{ filled: mediaPartner.description !== null }" mat-button (click)="goToMediaPartner(mediaPartner)">
							<img src="{{mediaPartner.apiLogoUrl}}" alt="Logo of {{mediaPartner.companyName}}"/>
						</button>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>