import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IParticipantType } from './../../core/_models/participants.interface';
import { ExhibitorService } from './../../core/_services/exhibitors/exhibitors.service';
import { IExhibitorType } from '../../core/_models/exhibitors.interface';
import { ISupporterType } from '../../core/_models/supporters.interface';
import { IMediaPartnerType } from '../../core/_models/media-partners.interface';
import { ISpeakerCollaboratorType } from '../../core/_models/speaker-collaborators.interface';


@Component({
  selector: 'app-exhibitors',
  templateUrl: './exhibitors.component.html',
  styleUrls: ['./exhibitors.component.scss']
})
export class ExhibitorsComponent implements OnInit {

  @Input() class_name: string = 'exhibitors_page';
  @Input() phrase: string = '';
  @Input() title1: string = 'EXHIBITORS';
  @Input() title2: string = '';

  //public dialogData: any = dialogData;
  participants: any = [];
  constructor
  (private router: Router, 
    //public dialog: MatDialog,
    private exhibitorService: ExhibitorService) { }

  allParticipants: IParticipantType;
  participantsData$: Observable<IParticipantType>;
  

  // openDialog(index:any) {
  //   if (this.dialogData[index].text !== null) {
  //     this.dialog.open(DynamicDialogComponent,
  //       {
  //         data: this.dialogData[index],
  //         panelClass: 'custom-modalbox' 
  //       });
  //   } else {
  //     return;
  //   }
  // }


  ngOnInit(): void {
    this.participantsData$ = this.exhibitorService.data$;
    this.exhibitorService.getParticipants();

    this.participantsData$.subscribe((res)=> {
			this.participants = res;
		})

    // this.participantsData$.subscribe((res)=> {
    //  this.participants.push(...res.exhibitors);
    //  this.participants.push(...res.mediaPartners);
    //  this.participants.push(...res.supporters);
    //  console.log(this.participants);
		// })

  }

  goToExhibitor(exhibitor: IExhibitorType): void {
    if (exhibitor.description) {
      this.router.navigateByUrl('/exhibitors/'+ this.replaceSpaces(exhibitor.companyName));
    }
  }

  goToSupporter(supporter: ISupporterType): void {
    if (supporter.description) {
      this.router.navigateByUrl('/exhibitors/'+ this.replaceSpaces(supporter.companyName));
    }
  }

  goToMediaPartner(mediaPartner: IMediaPartnerType): void {
    if (mediaPartner.description) {
      this.router.navigateByUrl('/exhibitors/'+ this.replaceSpaces(mediaPartner.companyName));
    }
  }

  goToSpeakerCollaborator(speakerCollaborator: ISpeakerCollaboratorType): void {
    if (speakerCollaborator.description) {
      this.router.navigateByUrl('/exhibitors/'+ this.replaceSpaces(speakerCollaborator.companyName));
    }
  }

  replaceSpaces(str: string): string {
    return str.replace(/ /g, '-').toLowerCase();
  }

}
